.alert {
    position: fixed;
    top: 0px;
    width: calc(100% - 400px);
    margin: 0 200px;
    min-height: 30px;
    z-index: 99;
    padding: 16px 32px;
    box-sizing: border-box;
    color: #2e2e2e;
    border-width: 1px;
    border-style: solid;
    display: flex;
    align-items: center;
}

.alert__message {
    margin-left: 16px;
}

.alert__close {
    position: relative;
    right: 32px;
    position: absolute;
    cursor: pointer;
}

.alert-info {
    color: #1c508e;
    border-color: #1c508e;
    background-color: lightblue;
}

.alert-warning {
    background-color: lightgoldenrodyellow;
    color: #9f8140;
    border-color: #9f8140;
}

.alert-error {
    background-color: #f7d7da;
    color: #7e2e38;
    border-color: #7e2e38;
    
}