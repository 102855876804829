* {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-smooth: auto;
}

@font-face {
    font-family: "Inter";
    src: local("Inter"), url("../fonts/Inter-Regular.ttf") format("truetype");
}

@font-face {
    font-family: "Inter Light";
    src: local("Inter Light"),
        url("../fonts/Inter-Light.ttf") format("truetype");
}

@font-face {
    font-family: "Inter Bold";
    src: local("Inter Bold"), url("../fonts/Inter-Bold.ttf") format("truetype");
}

@font-face {
    font-family: "IBM Plex Sans";
    src: local("IBM Plex Sans"),
        url("../fonts/IBMPlexSans-Regular.ttf") format("truetype");
}

html {
    -webkit-print-color-adjust: exact;
    font-family: "Inter", "Helvetica", "Arial";
}

.css-1mf6u8l-MuiSvgIcon-root-MuiSelect-icon {
    transition: all 0.6s ease-out;
}

body {
    font-family: "Inter", "Helvetica", "Arial";
    box-sizing: border-box;
    margin: 0px;
    height: 100%;
    padding: 0 !important;
}

main,
#root {
    height: 100%;
}

.content-bg {
    background-color: #e2e2e2;
}

.MuiFormControl-root {
    width: 100%;
}

.MuiInputAdornment-root {
    margin-right: 4px;
}

.MuiIconButton-root {
    padding-right: 0;
}

.red {
    font-family: "Inter", "Helvetica", "Arial";
    color: red;
}

.MuiButtonBase-root:hover {
    color: #000000;
    box-shadow: "none";
    background-color: transparent;
}

.MuiButtonBase-root:active {
    color: #000000;
    box-shadow: "none";
    background-color: transparent;
}

/* .PrivateSwitchBase-input:hover:active:focus {
  background-color: white;
} */

/* .MuiInput-underline:after {
  color: #888888;
} */

#links path {
    fill: none;
    cursor: zoom-in;
    stroke-linejoin: round;
}

#links path.no-zoom-in {
    cursor: default;
}

#treeAxis_background.zoomed-in {
    cursor: zoom-out;
}

#fanAxis_background.zoomed-in {
    cursor: zoom-out;
}

.tick text,
.cladeLegend text {
    font-size: 12px;
    font-family: "Inter", "sans-serif";
}

/* Color coding */

#legend_toggle {
    position: relative;
    cursor: pointer;
    padding: 10px 0 10px;
}

#legend_toggle span {
    position: absolute;
    top: 19px;
    right: 20px;
    display: block;
    width: 8px;
    height: 1px;
    background: #555;
    transform: rotate(-135deg);
    transform-origin: 100% 0%;
}

#legend_toggle span:after {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    bottom: 0;
    left: 100%;
    background: #555;
    transform: rotate(-90deg);
    transform-origin: 0% 100%;
}

div.active > #legend_toggle span {
    top: 21px;
    right: 16px;
    width: 11px;
    transform-origin: 50% 0%;
}

div.active > #legend_toggle span:after {
    bottom: -5px;
    left: 50%;
}

#colorCoding_legend_svg {
    display: none;
}

div.active > div > #colorCoding_legend_svg {
    display: block;
    /* height: 200px; */
    padding-top: 12px;
    padding-left: 10px;
    box-sizing: border-box;
}

header .MuiTypography-root {
    /* font-family: IBM Plex Sans; */
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 19px;
}

.introText {
    /* font-family: IBM Plex Sans; */
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 17px;
    letter-spacing: 0.01em;
    font-feature-settings: "tnum" on, "lnum" on;
}

/* .mapboxgl-ctrl-bottom-left {
  display: none !important;
}

.mapboxgl-ctrl-bottom-right {
  display: none !important;
} */

circle {
    transition: all 0.2s ease-in-out;
}

.MuiSelect-select.MuiSelect-select {
    padding-right: 24px;
    /* font-family: IBM Plex Sans; */
    font-style: normal;
    font-weight: normal;
    font-size: 17px;
    line-height: 22px;
    height: auto;
    transition-duration: 0s !important;
}

#legend_toggle {
    cursor: pointer;
    /* font-family: IBM Plex Sans; */
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 25px;
    padding: 10px 0 11px;
}

.no-break {
    page-break-inside: avoid;
}

@media print {
    .no-break {
        page-break-inside: avoid;
        position: relative;
    }
    .content-bg {
        background-color: #fff !important;
    }
    div {
        page-break-inside: avoid;
    }
    @page {
        margin: 24px 0;
    }
    @page :first {
        margin: 0 !important;
    }
}

.svg-bg {
    stroke-width: 0px;
    background-color: white;
}

.marker {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    cursor: pointer;
    background-color: red;
}

.mapboxgl-popup {
    max-width: 400px !important;
}

.mapboxgl-popup-content {
    font-family: "Open Sans", sans-serif;
    max-height: 450px !important;
    min-height: 400px;
    min-width: 220px;
    overflow: overlay;
}

.MuiSelect-standard:focus {
    background-color: #fff;
}

.mapboxgl-popup-content tr td:first-child {
    padding-right: 16px;
}

.mapboxgl-popup-content tr td:last-child {
    min-width: 24px;
}

.mapboxgl-popup-content {
    padding: 12px 16px;
    max-height: 200px;
}

.mapboxgl-popup-content h3 {
    margin-bottom: 0;
    line-height: 16px;
    font-size: 12px;
    margin-bottom: 12px;
}

.map-popup-cell {
    max-width: 200px;
    overflow-wrap: break-word;
    padding: 0;
    line-height: 16px;
    font-size: 12px;
    padding-bottom: 12px;
}

.mapboxgl-popup {
    transform: none !important;
    top: 10px;
    left: 10px;
}

.mapboxgl-popup-anchor-top .mapboxgl-popup-tip,
.mapboxgl-popup-anchor-bottom .mapboxgl-popup-tip,
.mapboxgl-popup-anchor-center .mapboxgl-popup-tip,
.mapboxgl-popup-anchor-left .mapboxgl-popup-tip,
.mapboxgl-popup-anchor-right .mapboxgl-popup-tip,
.mapboxgl-popup-anchor-bottom-right .mapboxgl-popup-tip,
.mapboxgl-popup-anchor-bottom-left .mapboxgl-popup-tip,
.mapboxgl-popup-anchor-top-right .mapboxgl-popup-tip,
.mapboxgl-popup-anchor-top-left .mapboxgl-popup-tip {
    display: none !important;
}

.mapboxgl-popup-close-button:hover {
    background-color: #fff;
}

.mapboxgl-popup-close-button {
    font-size: 23px;
    width: 35px;
    font-family: "Inter", "Helvetica", "Arial";
    color: #c3c3c3;
    font-weight: 100;
}

.clades-info-row--val {
    line-height: 13px;
}

.clades-info-row--label {
    line-height: 13px;
    margin-right: 3px;
}

.mapPopup__summary-row {
    line-height: 13px;
}

.mapPopup__summary-label {
    margin-right: 3px;
}

.mapPopup__clades {
    padding-bottom: 10px;
}

button.mapboxgl-popup-close-button:focus {
    outline: 0;
}

.labelAnchor rect {
    fill: #ffffff;
    stroke: #4f4f4f;
    stroke-width: 1;
}

.tooltip-container {
    position: absolute;
    background: #fff;
    border: 1px solid #969696;
    padding: 3px 4px 3px 6px;
    color: #000;
    border-radius: 5px;
    text-align: justify;

    font-size: 11px;
    font-family: "Inter";
    display: flex;
    justify-content: center;
    align-items: center;
    bottom: -30px;
    left: 50%;
    transform: translateX(-50%);
    text-align: left;
}

.labelBorder {
    fill: #ffffff;
    opacity: 0.75;
}

.labelText {
    font-size: 12px;
}

.cladeLabelAnchorPoint circle {
    fill: #ffffff;
    stroke: #4f4f4f;
    stroke-width: 1;
}

.cladeLabelBorder {
    fill: #ffffff;
    opacity: 0.75;
    stroke: #4f4f4f;
    stroke-width: 1;
}

.cladeLabelText {
    font-size: 12px;
}

.mutationLabelAnchorPoint rect {
    fill: #ffffff;
    stroke: #4f4f4f;
    stroke-width: 1;
}

.mutationLabelBorder {
    fill: white;
    opacity: 0.5;
}

.mutationLabelText {
    font-size: 8px;
}

*::-webkit-scrollbar {
    width: 0.4em;
    height: 0.4em;
}

*::-webkit-scrollbar-track {
    -webkit-border-radius: 10px;
    border-radius: 10px;
}

*::-webkit-scrollbar-thumb {
    -webkit-border-radius: 10px;
    border-radius: "10px";
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
    background-color: rgb(128, 128, 128);
    display: none;
}

*:hover::-webkit-scrollbar-thumb {
    display: block;
}

*:hover::-webkit-scrollbar {
    display: block;
}

.error-page {
    width: 100vw;
    height: 100vh;
    background-color: rgb(93, 90, 90);
    display: flex;
    align-items: center;
    justify-content: center;
}

.error-page-text {
    color: white;
}

.error-page-warning {
    display: flex;
    font-size: 20px;
    align-items: center;
    margin-bottom: 15px;
}

.error-page-small-text {
    font-size: 14px;
    margin-left: 36px;
    margin-bottom: 10px;
}

.error-page-link {
    color: #f7fc8d;
}

.error-page-button {
    background-color: rgb(93, 90, 90);
    border-radius: 4px;
    width: 60px;
    color: #fff;
    padding: 5px;
    border: #fff 1px solid;
    margin-left: 36px;
    margin-bottom: 30px;
}
