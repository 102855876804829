.mapPopup {
    display: flex;
    flex-direction: column;
    font-family: 'Inter', 'Verdana';
    overflow-y: overlay;
}

.mapPopup__chart {
    margin: 10px auto;
}

.mapPopup__summary{
    border-bottom: 1px solid silver;
    margin: 15px 0;
    padding-bottom: 10px;
    font-family: 'Inter', 'Verdana';
}

.mapPopup__summary-row {
    display: flex;
    align-items: center;

}

.mapPopup__summary-label {
    font-weight: bold;
    font-size: 12px;
    line-height: 15px;
    margin-right: 10px;
    font-family: 'Inter', 'Verdana';
}

.mapPopup__clades{
    display: flex;
    align-items: flex-start;
    font-family: 'Inter', 'Verdana';
}

.mapPopup__clades-circle {
    width: 17px;
    height: 17px;
    border-radius: 50%;
    margin-right: 10px;
    margin-top: 1px;
}

.mapPopup__chart-single{
    width: 100px;
    height: 100px;
    border-radius: 50%;
    margin: 0 auto;
}

.clades-info-row {
    display:flex;
    align-items: center;
}

.clades-info-row--label {
    font-weight: bold;
    font-size: 12px;
    line-height: 15px;
    margin-right: 10px;

}